import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import store from "../store";
import NotFoundView from "../views/NotFoundView.vue"
import Dashboard from "../components/Dashboard/Dashboard.vue"
import Files from "../components/Files/Files.vue"
import AuditLog from "../components/AuditLog/AuditLog.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/files/:path(.*)?',
    name: 'Files',
    component: Files,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/log',
    name: 'AuditLog',
    component: AuditLog,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundView
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

let info = {};
if(sessionStorage.getItem("tokenAccess")) info.tokenAccess = sessionStorage.getItem("tokenAccess");
if(sessionStorage.getItem("username")) info.username = sessionStorage.getItem("username");
if(sessionStorage.getItem("operation")) info.operation = sessionStorage.getItem("operation");

router.beforeEach((to, from, next) => {
  if(to.matched.some((record) => record.meta.requiresAuth)){
    if(store.state.auth || (info.tokenAccess && info.username && info.operation)){

      store.dispatch("doLogin", info.username);
      if(Object.keys(info).length !== 0) store.commit("globalInfoMutation", info);

      // if(store.state.permission.find(value => to.fullPath.includes(value.route))){
      //   let validate = store.state.permission.find(value => to.fullPath.includes(value.route))
      //   if(validate && validate.permission){
      //     next();  
      //   }else{
      //     next({ name: "NotFound" });    
      //   }
      // }else{
      //   next();
      // }
      next();
    }else{

      next({ name: "LoginView" });
    }
  }else{
    next();
  }
});
export default router
