<template>
    <div>
      <div class="ml-3 mr-3 mt-3">
        <v-card class="scroll elevation-1" outlined>
          <v-overlay color="#FFFFFF" :value="loadingFiles" absolute opacity="1">
            <v-progress-circular color="#000000" indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-toolbar color="#FFFFFF" elevation="1" dense height="50" class="app-bar">
            <NavegationFiles :path="path" @changeNavegation="changeNavegationPath"/>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on" fab color="#FFFFFF" class="ml-3 btn-navegation" x-small @click="removePath()"><v-icon>arrow_back</v-icon></v-btn>
              </template>
              <span>{{$t('back')}}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on" fab color="#FFFFFF" class="ml-3 btn-navegation" x-small @click="getFiles()"><v-icon>refresh</v-icon></v-btn>
              </template>
              <span>{{$t('refresh')}}</span>
            </v-tooltip>
          </v-toolbar>
          <v-data-table dense :headers="headersTableFiles" :items="bodyTableFiles" class="scroll table-files pl-0 elevation-0 rounded-0 pt-0" :footer-props="footerProps" :hide-default-footer="hideFooter" :items-per-page="500" :mobile-breakpoint="0" :search="search" fixed-header>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td v-for="(header, i) in headersTableFiles" :key="i" @contextmenu.prevent="showMenuFile($event, item)">
                    <div v-if="header.value == 'name' && item.icon == 'folder'" :class="item.icon == 'folder' ? 'folder':''" @click="addToPath(item?.name + '/')">
                      <span><v-icon class="mr-2">{{item.icon}}</v-icon>{{item[header.value]}}/</span>
                    </div>
                    <div v-else>
                      <span >{{item[header.value]}}</span>
                    </div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="header.value === 'actions' && item.isDirectory === '0' && !item.loadingUpload" v-bind="attrs" v-on="on" icon @click="uploadServerFile(item.fullName)">
                          <v-icon>download_for_offline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('file_download') }}</span>
                    </v-tooltip>
                    <v-progress-circular class="mx-2" v-if="header.value === 'actions' && item.isDirectory === '0' && item.loadingUpload" :width="4" size="20" color="#C00AC0" indeterminate></v-progress-circular>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="header.value === 'actions' && item.isDirectory === '0'" v-bind="attrs" v-on="on" icon @click="openDialogConfig(item.fullName)">
                          <v-icon>settings</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('file_info')}}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </div>


    <v-dialog v-model="dialogDelete" max-width="400" persistent>
			<v-card class="scroll">
				<v-card-title class="text-center"><p class="text-dialog-confirm">{{$t('file_delete_confirm')}}</p></v-card-title>
				<v-card-text class="mt-4 mb-1">
					<v-row align="center" justify="space-around">
						<div>
							<v-btn color="#C00AC0" @click="deleteFile()" class="mr-3 white--text">{{$t('accept')}}</v-btn>
							<v-btn outlined color="#061A40" @click="dialogDelete = false;">{{$t('cancel')}}</v-btn>
						</div>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

    <v-dialog v-model="dialogAccesFolder" max-width="450" persistent>
			<v-card class="scroll">
				<v-card-title class="text-center justify-center">
          <p class="text-dialog-confirm-title mt-2 mb-6">{{ $t('caution_uppercase') }} <v-icon color="#C00AC0" class="mb-1">error</v-icon></p>
          <p class="text-dialog-confirm mb-0">{{$t('folder_access_confirm')}}</p>
        </v-card-title>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <p class="mr-3 confirm-count"> {{ countConfirmAccess }} / 5</p>
        </div>
				<v-card-text class="mt-4 mb-1">
					<v-row align="center" justify="space-around">
						<div>
							<v-btn color="#C00AC0" @click="countConfirmAccess++; changeAccessFolder(fileSelected);" class="mr-3 white--text">{{$t('accept')}}</v-btn>
							<v-btn outlined color="#061A40" @click="dialogAccesFolder = false; countConfirmAccess = 0;">{{$t('cancel')}}</v-btn>
						</div>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

    <v-dialog v-model="dialogConfig" max-width="550" persistent>
      <v-card class="scroll">
        <v-card-title>
          <div class="title-dialog-config d-flex">
            <p style="max-width: 450px;" class="title-dialog-config mb-0">{{ fileSelectedConfig?.file_name }}</p>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="copyPublicUrl(fileSelectedConfig?.full_name)" class="ml-auto" v-show="fileSelectedConfig?.is_public == '1'" v-bind="attrs" v-on="on" icon><v-icon>content_copy</v-icon></v-btn>
            </template>
            <span>{{$t('copy_public_url')}}</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="mb-1">
          <div class="ma-2 mb-7">
            <p><b>{{$t('size')}}: </b>{{ fileSelectedConfig?.size }} <b>MB</b></p>
            <p><b>{{$t('updated_at')}}: </b>{{ fileSelectedConfig?.updated }}</p>
            <div class="d-flex">
              <p class="mb-0 mt-1"><b>{{$t('access')}}: </b>{{ fileSelectedConfig?.is_public == 1  ? $t('public') : $t('restricted')}}</p>
              <!-- <v-icon @click="changeAccessFile(fileSelectedConfig?.full_name)" class="ml-2 mb-1" v-if="fileSelectedConfig?.is_public == '1'">lock</v-icon> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="changeAccessFile(fileSelectedConfig?.full_name)" v-show="fileSelectedConfig?.is_public == '0'" v-bind="attrs" v-on="on" class="ml-2">lock_open</v-icon>
                </template>
                <span>{{$t('give_public_access')}}</span>
              </v-tooltip>
            </div>
          </div>
          <v-row class="mt-3" justify="end">
            <div>
              <v-btn outlined color="#061A40" @click="dialogConfig = false;">{{$t('close')}}</v-btn>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

      <FloatingButton :path="path" @refreshFiles="getFiles()"/>
      <MenuComponent v-if="!$vuetify.breakpoint.xs" name='Menu files' :menu-items='menuFileItems' @dan-menu-click='onMenuItemClick' :openMenu="menuFile" :positionX="xFile" :positionY="yFile"/>
      <v-snackbar color="#C00AC0" v-model="snackbarError" timeout="4000">
      {{snackbarErrorMessage}}
        <template v-slot:action="{ attrs }">
          <v-btn color="#1C1133" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
import MenuComponent from '../../components/Menus/SubMenu.vue'
import NavegationFiles from './NavegationFiles.vue'
import FloatingButton from './FloatingButton.vue'
export default {
  name: 'FilesComponent',
  mixins: [sharedMethods],
  components:{
    MenuComponent,
    NavegationFiles,
    FloatingButton
  },
  data(){
    return {
      snackbarError: false,
      snackbarErrorMessage: '',
      loadingFiles: false,
      headersTableFiles: [{text: this.$t('name'), value: 'name'}, {text: this.$t('date'), value: 'date'}, {text: '', value: 'actions', sortable: false, align: 'end' }],
      bodyTableFiles: [],
      search: '',
      file: null,
      fileName: '',
      menuFile: false,
      xFile: 0,
      yFile: 0,
      fileSelected: {},
      fileSelectedConfig: {},
      dialogDelete: false,
      dialogAccesFolder: false,
      countConfirmAccess: 0,
      dialogConfig: false,
      path: '',
      menuFileItems: [],
      footerProps: {'items-per-page-options': [200, 500, 1000, -1]}
    }
  },
  watch: {
    path(val){
      this.getFiles()
      if (val !== this.$route.params.path) {
        this.$router.push({ path: `/files/${val}`, replace: true  }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        }); // Actualizar la ruta utilizando Vue Router
      }
    },
    '$route.params.path'(val){
      if (val !== this.path) {
        this.path = (val && val.endsWith('/')) ? val : (val ? val + '/' : '');
      }
    }
  },
  computed: {
    hideFooter(){
      return this.bodyTableFiles.length < 200
    }
  },
  created() {

  },mounted(){
    this.getFiles()
  },methods: {
    getFiles(){

      this.bodyTableFiles = [];
      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("path", this.path);
      formData.append("action", 'listFiles');

      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      this.loadingFiles = true;
      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.axios.post(url, formData, headers).then(async (result) => {
          if(result.data.code == '200'){
            let information = result.data.msg
            if(information == null){
              this.loadingFiles = false;
              return
            }
            let bodyTableTemporal = [];
            information.forEach(element => {
              bodyTableTemporal.push({name: (element?.file_name) ? element?.file_name : element.name, date: element.updated, type: element.contentType, isDirectory: element.isDirectory, fullName: element.name, loadingUpload: false})
            });
            this.organizeObject(bodyTableTemporal)
          }
          this.loadingFiles = false;
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })
    },
    uploadServerFile(file){
      if(!file){
        this.snackbarErrorMessage = this.$t('file_select')
        this.snackbarError = true
        return
      }
      this.loadingFiles = true;
      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", file);
      formData.append("action", 'downloadFile');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.axios.post(url, formData, headers).then(async (result) => {
          if(result.data.code == '200'){
            this.snackbarErrorMessage = this.$t('file_download_successfully')
            this.snackbarError = true;
            let response = result.data.msg

            let filename = response.name;
            let base64Content = response.file;

            let byteCharacters = atob(base64Content);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], { type: 'application/octet-stream' });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            link.click();
            this.loadingFiles = false;
            window.URL.revokeObjectURL(link.href);
          }
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })
    },
    onMenuItemClick(item){
      if(item.action){
        item.action()
      }
      this.menuFile = false
    },
    showMenuFile(event, item){
      this.fileSelected = {}
      this.fileSelected = item
      if(item?.isDirectory == '1'){
        this.menuFileItems = [
          {name: this.$t('give_public_access'), action: () => {this.dialogAccesFolder = true;}},
          {name: this.$t('delete'), action: () => {this.dialogDelete = true;}},
        ]
      }else{
        this.menuFileItems = [
          {name: this.$t('delete'), action: () => {this.dialogDelete = true;}},
          {name: this.$t('download'), action: () => {this.uploadServerFile(this.fileSelected.fullName)}},
        ]
      }
      this.menuFile = false;
      this.xFile = event.clientX;
      this.yFile = event.clientY;
      this.$nextTick(() => {
        this.menuFile = true;
      })
    },
    async deleteFile(){
      if(!this.fileSelected.name){
        this.snackbarErrorMessage = this.$t('file_select')
        this.snackbarError = true
        return
      }

      if(this.fileSelected?.isDirectory == '1'){
        this.fileSelected.name += '/';
      }
      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", this.path+this.fileSelected.name);
      formData.append("action", 'deleteFile');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      this.loadingFiles = true;
      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.dialogDelete = false
      await this.axios.post(url, formData, headers).then(async (result) => {
        let response = result.data;
          if(response.code == '200'){
            this.snackbarErrorMessage = this.$t('delete_successfully');
            this.snackbarError = true
          }else if(response?.msg?.code == 403){
            this.snackbarErrorMessage = this.$t('delete_6_month');
            this.snackbarError = true
          }else if(response?.msg?.code == 409){
            this.snackbarErrorMessage = this.$t('delete_6_month_folder');
            this.snackbarError = true
          }else{
            this.snackbarErrorMessage = this.$t('been_problem_delete_file');
            this.snackbarError = true
          }
          this.loadingFiles = false;
          await this.getFiles();
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })
      this.fileSelected = {}
    },
    organizeObject(items){
      try {
        items.forEach(element => {
          if(element?.isDirectory == '1') element.icon = 'folder'
        });
        items.sort((a, b) => {
          if (a.icon === "folder" && b.icon !== "folder") return -1
        });
        this.bodyTableFiles = items
      }catch(error) {
        console.warn(error)
      }
    },
    addToPath(text){
      this.path += text;
    },
    removePath(){
      let temporalPath = this.path.split('/');
      if (temporalPath.length > 1) {
        temporalPath.splice(temporalPath.length - 2, 2);
        this.path = (temporalPath.join('/') + '/') != '/' ? temporalPath.join('/') + '/' : '';
      }
    },
    changeNavegationPath(text){
      this.path = text;
    },
    async openDialogConfig(fileName){

      if(!fileName){
        this.snackbarErrorMessage = this.$t('file_select')
        this.snackbarError = true
        return
      }

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", fileName);
      formData.append("action", 'detailsFile');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.dialogDelete = false
      await this.axios.post(url, formData, headers).then(async (result) => {
        let response = result.data;
        if(response.code == '200' && response.msg){
          this.dialogConfig = true
          this.fileSelectedConfig = response?.msg;
        }
      })
      .catch((error) => {
        console.error(error)
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })

    },
    async changeAccessFile(fileName){
      this.dialogConfig = true

      if(!fileName){
        this.snackbarErrorMessage = this.$t('file_select')
        this.snackbarError = true
        return
      }

      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("file_name", fileName);
      formData.append("action", 'changeAccessFile');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.dialogDelete = false
      await this.axios.post(url, formData, headers).then(async (result) => {
        let response = result.data;
        if(response.code == '200' && response.msg){
          this.openDialogConfig(fileName);
        }
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })
      this.dialogConfig = true

    },
    async changeAccessFolder(folderName){

      if(!folderName || folderName?.isDirectory == '0'){
        this.snackbarErrorMessage = this.$t('folder_select')
        this.snackbarError = true
        return
      }

      if(this.countConfirmAccess < 5){
        return
      }


      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("folder_name", this.path+folderName.name);
      formData.append("action", 'changeAccessFolder');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.loadingFiles = true;
      this.dialogAccesFolder = false;
      this.countConfirmAccess = 0;
      await this.axios.post(url, formData, headers).then(async (result) => {
        let response = result.data;
        if(response.code == '200' && response.msg){
          this.snackbarErrorMessage = this.$t('folder_access_successfully')
          this.snackbarError = true
        }else{
          this.snackbarErrorMessage = this.$t('been_problem')
          this.snackbarError = true
        }
        this.loadingFiles = false;
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem')
        this.snackbarError = true
      })

    },
    copyPublicUrl(fileName){
      let fullUrl = encodeURI('https://storage.googleapis.com/wvox-'+this.$store.getters.globalInfo.operation+'/'+fileName);
      this.copyToClipboard(fullUrl)
    },
  }
}
</script>
<style scoped>
.text-files{
	color: #061A40;
  font-size: 23px;
	font-weight: 700;
}
.v-data-table >>> th {
  text-transform: uppercase;
  background-color: #F5F5F5!important;
}
.table-files{
	height: calc(100vh - 134px);
}
.table-files-mobile{
	height: calc(100vh - 260px);
}
.scroll{
  overflow: auto!important;
}
.scroll::-webkit-scrollbar{
  -webkit-appearance: none;
}
.scroll::-webkit-scrollbar:vertical{
  width:10px;
}
.scroll::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button{
  display: none;
}
.scroll::-webkit-scrollbar:horizontal{
  height: 10px;
}
.scroll::-webkit-scrollbar-thumb{
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}
.scroll::-webkit-scrollbar-track{
    border-radius: 10px;
}
.mytable table thead tr th{
  background: #F5F5F5!important;
}
.mytable table tbody td{
  height: 32px!important;
}
.tdHeadTable{
  font-family: "Montserrat", sans-serif;
  color: #000000;
}
.aSearch{
  color: #000000;
  font-size: 13px;;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.btn-see{
  margin-top: 1px;
}
.text-menu-mobile{
	color: #061A40;
	font-weight: 700;
}
.btn-navegation{
  color: #E0E0E0!important;
}
.btn-navegation >>> .v-btn__content{
  color: #000000;
}
.text-dialog-confirm{
	white-space:pre-wrap;
  word-break:break-word;
}
.text-dialog-confirm-title{
	white-space:pre-wrap;
  word-break:break-word;
  color: #C00AC0;
}
.folder{
  color: #1C1133;
  font-weight: 500;
}
.folder:hover{
  color: #1C1133;
  cursor: pointer;
  transition: color 0.3s;
  font-weight: 800;
}
.title-dialog-config {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.v-data-table >>> td:last-child {
  text-align: right; /* Alinea el contenido a la derecha */
}

.confirm-count{
  font-weight: 700;
  color: #1C1133;
}
</style>
