<template>
    <div>
      <v-app-bar v-if="!$vuetify.breakpoint.xs" class="app-bar" app color="#FFFFFF" elevation="1" fixed clipped-left dense height="60">
        <div class="navigation ma-0 pa-0" style="background-color: #1C1133;">
          <v-app-bar-nav-icon id="navigation" @click.stop="mini = !mini;" class="pl-0" dark></v-app-bar-nav-icon>
        </div>
        <div class="d-flex align-center">
          <div class="background-logo pr-8">
          <router-link to="/dashboard"><v-img class="ml-8 mt-3" contain src="../../assets/Logo_wolkvox_storage.png" transition="scale-transition" width="110"/></router-link>
          </div>
        </div>
        <div style="max-width: 300px;" class="ml-5">
          <p class="text-user mt-3 mb-0">{{ $t('operation') }}</p>
          <p class="text-operation pt-0 mt-0 mt-0 mb-0 pb-4">{{$store.getters.globalInfo.operation}}</p>
          </div>
          <v-divider class="divider ml-4 mr-4" inset vertical></v-divider>
          <div style="max-width: 300px;">
            <p class="text-user mt-3 mb-0">{{ $t('user') }}</p>
            <p class="text-operation pt-0 mt-0 mt-0 mb-0 pb-4">{{$store.getters.globalInfo.username}}</p>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="mr-1" icon @click="dialogLogout = true;"><v-icon color="#000000">logout</v-icon></v-btn>
      </v-app-bar>
      <v-navigation-drawer v-if="$store.state.auth && !$vuetify.breakpoint.xs" v-model="drawer" clipped enable-resize-watcher app dark color="#1C1133" :mini-variant="mini" stateless width="230">
        <v-divider class="divider"></v-divider>
        <v-list-item-group>
          <v-list dense nav class="pl-0 pr-0">
              <v-list-item :to="item.path" v-for="item in sidebarMenu.filter((i) => i.visible !== false)" :key="item.title" link :class="(mini)? 'link2' : 'link'" :active-class="(mini)? 'link-active2' : 'link-active'">
                <v-list-item-icon class="ml-2" v-if="item.icon != ''">
                <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-2">
                <v-list-item-title>{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-list>
        </v-list-item-group>
      </v-navigation-drawer>
  
      <!-- navegacion mobile -->
      <v-app-bar v-if="$store.state.auth && $vuetify.breakpoint.xs" class="app-bar" app color="#1C1133" elevation="1" fixed clipped-left dense height="60">
        <div class="navigation ma-0 pa-0" style="background-color: #2763AE;">
          <v-app-bar-nav-icon id="navigation" @click.stop="drawerMobile = !drawerMobile; mini = false;" class="pl-0" dark></v-app-bar-nav-icon>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <div class="background-logo-mobile">
          <router-link to="/dashboard"><v-img class="ml-8 mt-3" contain src="../../assets/Logo_wolkvox_storage.png" transition="scale-transition" width="110"/></router-link>
          </div>
        </div>
        <v-spacer></v-spacer>
        
        <v-menu transition="slide-y-transition" top rounded="0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon color="#ffffff">more_vert</v-icon></v-btn>
          </template>
          <v-list color="#640964" class="pa-0 pt-4 pb-1">
            <v-list-item @click="dialogLogout = true"><v-list-item-icon class="ma-1"><v-icon color="#FFFFFF">logout</v-icon></v-list-item-icon></v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
  
      <v-navigation-drawer v-if="$store.state.auth && $vuetify.breakpoint.xs" v-model="drawerMobile" app dark color="#1C1133" temporary absolute>
        <v-divider class="divider"></v-divider>
        <v-list-item-group>
          <v-list class="list-menu">
            <div v-for="item in sidebarMenuMobile.filter((i) => i.visible !== false)" :key="item.title">
              <v-list-group class="link-active-mobile" v-if="item.items" v-model="item.active" :prepend-icon="item.action" @click="iconActive = !iconActive" :append-icon="iconActive ? 'arrow_right' : 'arrow_drop_down'" no-action link>
                <template v-slot:activator>
                  <v-list-item-icon class="ml-2" v-if="item.icon != ''">
                    <v-icon>{{item.icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <!-- eslint-disable-next-line -->
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                  <v-list-item v-for="subItem in item.items" :key="subItem.title" :to="item.path + '#' + subItem.title" @click="changeMenuReport(subItem.title, subItem.item)">
                    <v-list-item-content>
                      <!-- eslint-disable-next-line -->
                      <v-list-item-title v-text="subItem.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list-group>
              <v-list-item v-else link class="link" active-class="link-active" :to="item.path">
                <v-list-item-icon class="ml-2" v-if="item.icon != ''">
                  <v-icon>{{item.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <!-- eslint-disable-next-line -->
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-list-item-group>
      </v-navigation-drawer>

      <v-dialog v-model="dialogLogout" max-width="341" persistent>
        <v-card class="scroll">
          <v-card-title class="text-center"><p class="text-dialog-confirm">{{$t('accept_logout')}}</p></v-card-title>
          <v-card-text class="mt-4 mb-1">
            <v-row align="center" justify="space-around">
              <div>
                <v-btn color="#C00AC0" @click="onLogout()" class="mr-3 white--text">{{ $t('accept') }}</v-btn>
                <v-btn outlined color="#061A40" @click="dialogLogout = false;">{{ $t('cancel') }}</v-btn>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  import sharedMethods from '@/mixins/sharedMethods';
  export default {
    name: 'NavegationMenu',
    components: {
    },
    mixins:[sharedMethods],
    data() {
      return{
        iconActive: true,
        drawer: true,
        drawerMobile: false,
        mini: true,
        dialogLogout: false,
        routes: [
          {title: 'Dashboard', path: "/dashboard", icon: "equalizer", visible: true, notification: "", category: 'performance'},
          {title: this.$t('file_manager'), path: "/files", icon: "attach_file", visible: true, notification: "", category: 'performance'},
          {title: this.$t('audit_log'), path: "/log", icon: "description", visible: true, notification: "", category: 'performance'},
        ],
        routesMobile: [
          {title: 'Dashboard', path: "/dashboard", icon: "equalizer", visible: true, notification: "", category: 'performance'},
          {title: this.$t('file_manager'), path: "/files", icon: "attach_file", visible: true, notification: "", category: 'performance'},
          {title: this.$t('audit_log'), path: "/log", icon: "description", visible: true, notification: "", category: 'performance'},
        ],
      }
    },
    computed:{
      sidebarMenu: function () {
        if(this.mini){
          this.routes.forEach((element) => {
            if(element.icon == "") {
              element.visible = false;
            }
          });
        }else{
          this.routes.forEach((element) => {
            if(element.icon == ""){
              element.visible = true;
            }
          });
        }
        return this.routes.filter((i) => i.visible !== false);
      },
      sidebarMenuMobile: function () {
        if(this.mini){
          this.routesMobile.forEach((element) => {
            if(element.icon == "") {
              element.visible = false;
            }
          });
        }else{
          this.routesMobile.forEach((element) => {
            if(element.icon == ""){
              element.visible = true;
            }
          });
        }
        return this.routesMobile.filter((i) => i.visible !== false);
      },
    },
    created(){
      // this.filterMenu();
    },
    mounted(){
    },
    methods: {
      onLogout() {
        this.dialogLogout = false;
        this.logout();
      },
      filterMenu(){ // limpia los menus dependiendo de los permisos
        this.routes.forEach(element => {
          let route = this.$store.getters.permission.find((value) => value.route == element.path)
          if(route && element.path == route.route){
            element.visible = route.permission
          }
        });
        this.routesMobile.forEach(element => {
          let route = this.$store.getters.permission.find((value) => value.route == element.path)
          if(route && element.path == route.route){
            element.visible = route.permission
          }
        });
        this.filterTittleMenu()
        this.filterTittleMenuMobile()
      },
      filterTittleMenu(){ // limpia las categorias del menu
        let performance = 0;
        let design = 0;
        let tools = 0;
        this.routes.forEach(element => {
          if(element.category == 'performance' && element.visible === true){
            performance++
          }
          if(element.category == 'design' && element.visible === true){
            design++
          }
          if(element.category == 'tools' && element.visible === true){
            tools++
          }
        });
        if(performance == 0){
          this.routes = this.routes.filter(i => i.menu != 'performance')
        }
        if(design == 0){
          this.routes = this.routes.filter(i => i.menu != 'design')
        }
        if(tools == 0){
          this.routes = this.routes.filter(i => i.menu != 'tools')
        }
      },
      filterTittleMenuMobile(){ // limpia las categorias del menu
        let performance = 0;
        let design = 0;
        let tools = 0;
        this.routesMobile.forEach(element => {
          if(element.category == 'performance' && element.visible === true){
            performance++
          }
          if(element.category == 'design' && element.visible === true){
            design++
          }
          if(element.category == 'tools' && element.visible === true){
            tools++
          }
        });
        if(performance == 0){
          this.routesMobile = this.routesMobile.filter(i => i.menu != 'performance')
        }
        if(design == 0){
          this.routesMobile = this.routesMobile.filter(i => i.menu != 'design')
        }
        if(tools == 0){
          this.routesMobile = this.routesMobile.filter(i => i.menu != 'tools')
        }
      },
  
    }
  };
  </script>
  
  <style scoped>
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  .app-bar >>> .v-toolbar__content {
    padding-left: 0px!important;
  }
  .app-bar.elevation-1{
    box-shadow: 0px 0.3px 0.3px 0.3px #eeeeee !important;
  }
  #navigation{
    width: 56px;
    height: 60px;
    border-radius: 0px;
    background-color: #1C1133;
    color: white;
  }
  .background-logo{
    background-color: #1C1133;
    height: 60px;
    margin: 0px;
  }
  .background-logo-mobile{
    background-color: #1C1133;
    min-width: 174px;
    height: 60px;
    margin: 0px;
    display: block;
    margin: 0 auto;
  }
  .avatar {
    margin-left: 10px;
    margin-right: 10px;
  }
  .text-operation{
    font-weight: 700;
    line-height: 15px;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-user{
    font-weight: 500;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .link-active,
  .link-after,
  .link:before{
    border-radius: 0px 2em 2em 0px !important;
    background: #640964;
    padding-right: 10px!important;
    color: #eeeeee;
  }
  
  .link-active-mobile >>>  .v-list-item--active,
  .link-active-mobile >>>  .v-list-item--active:before{
    border-radius: 0px 2em 2em 0px !important;
    background: #640964;
    padding-right: 10px!important;
    color: #eeeeee;
  }
  .link-active-mobile >>> .v-list-group__items{
    background-color: #1C1133;
  }
  .link:hover{
    background: #640964;
    opacity: 0.5;
  }
  .link2:after,
  .link2:before{
    background: #640964;
    color: #eeeeee;
  }
  .link2:hover{
    background: #640964;
    opacity: 0.5;
  }
  
  .link-active2{
    background: #640964;
  }
  .light::-webkit-scrollbar {
    width: 15px;
  }
  
  .light::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
  }
  
  .light::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
  }
  
  .light::-webkit-scrollbar-thumb:hover {
    background: black;
  }
  
  .dark::-webkit-scrollbar {
    width: 15px;
  }
  
  .dark::-webkit-scrollbar-track {
    background: #202020;
    border-left: 1px solid #2c2c2c;
  }
  
  .dark::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border: solid 3px #202020;
    border-radius: 7px;
  }
  
  .dark::-webkit-scrollbar-thumb:hover {
    background: white;
  }
  .divider {
    margin: 0px 10px 0px 10px;
  }
  .v-divider--inset{
    height: 35%;
    margin-top: 20px!important;
  }
  .dialog-logout{
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .dialog-logout .v-btn{
    margin: 8px;
  }
  .list-menu{
    max-height: calc(100vh - 65px)!important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::v-deep ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::v-deep ::-webkit-scrollbar:vertical {
    width:10px;
  }
  ::v-deep ::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
    display: none;
  }
  ::v-deep ::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  ::v-deep ::-webkit-scrollbar-track {
    border-radius: 10px;  
  }
  .text-dialog-confirm{
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
  </style>