export default {
    data (){
      return {
      }
    },
    created(){
    },
    methods: {
      logout(){
        this.$store.dispatch("doLogout"); //logout en vue
        sessionStorage.setItem("tokenAccess", '');
        sessionStorage.setItem("username", '');
        sessionStorage.setItem("operation", '');
        this.$router.push("/"); //enviar al login
        setTimeout(function() {  // recargar pagina al hacer logout, para limpiar variables
          location.reload()
        }, 100);
      },
      getCurrentDateGlobal(){
        let hoy = new Date();
        let month = hoy.getMonth() + 1;
        let day = hoy.getDate();
        let hour = hoy.getHours()
        let minutes = hoy.getMinutes()
        if(parseInt(month) < 10){
          month = '0'+month
        }
        if(parseInt(day) < 10){
          day = '0'+day
        }
        if(parseInt(hour) < 10){
          hour = '0'+hour
        }
        if(parseInt(minutes) < 10){
          minutes = '0'+minutes
        }
        return hoy.getFullYear().toString() + month.toString() + day.toString();
      },
      getCurrentHour(){
        let hoy = new Date();
        let hour = hoy.getHours()
        let minutes = hoy.getMinutes()
        let seconds = hoy.getSeconds()

        if(parseInt(hour) < 10){
            hour = '0'+hour
        }
        if(parseInt(minutes) < 10){
            minutes = '0'+minutes
        }
        if(parseInt(seconds) < 10){
            seconds = '0'+seconds
        }
        return hour.toString()+minutes.toString()+seconds.toString()
      },
      downloadHTML(html, tittle, type){
        let pom = document.createElement('a');
        pom.setAttribute('href', 'data:'+ type +'charset=utf-8,' + encodeURIComponent(html));
        pom.setAttribute('download', tittle);

        if(document.createEvent){
          var event = document.createEvent('MouseEvents');
          event.initEvent('click', true, true);
          pom.dispatchEvent(event);
        }else{
          pom.click();
        } 
      },
      desktopNotification(title, message){
        if(!("Notification"  in  window)){   
          alert("Este navegador no soporta notificaciones de escritorio");  
        }  
        else if(Notification.permission === "granted"){
          let options = {body: message, icon:   "../assets/logo-manager.png",};
          let notification = new Notification(title, options);
        }  
        else if(Notification.permission  !==  'denied'){
          Notification.requestPermission(function (permission){
            if(!('permission' in Notification)){
              Notification.permission = permission;
            }
            if(permission === "granted"){
              let  options = {body: message, icon:   "../assets/logo-manager.png",};     
              let notification = new Notification(title, options);
            }   
          });  
        }
      },
      validateEmail(email){
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
      },
      isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if((charCode > 31 && (charCode < 48 || charCode > 57))){
          evt.preventDefault();
        }else{
          return true;
        }
      },
      copyToClipboard(text){
        if (navigator.clipboard) { // default: modern asynchronous API
          return navigator.clipboard.writeText(text);
        } else if (window.clipboardData && window.clipboardData.setData) {     // for IE11
          window.clipboardData.setData('Text', text);
          return Promise.resolve();
        } else {
          let dummy = document.createElement("input");
          dummy.style.display = 'none';
          document.body.appendChild(dummy);
  
          dummy.setAttribute("id", "dummy_id");
          document.getElementById("dummy_id").value=text;
          dummy.select();
          document.execCommand("copy");
          document.body.removeChild(dummy);
        }
      },
      validateErrors(error){
        if(error == '000'){
          return 'Internet problems!!'
        }
        if(error == '001'){
          return 'Unable to perform query on the BD! / Many records or duplicate record!'
        }
        if(error == '002'){
          this.logout()
          return 'This user is not found in the database!!'
        }
        if(error == '003'){
          return 'You do not have permission to perform this action!!'
        }
        if(error == '004'){
          return 'At this time our system has problems!!'
        }
        if(error == '005'){
          return 'At this time our system has problems!!!!'
        }
        if(error == '006'){
          return 'You can not create more active, perhaps no licenses available!!'
        }
        if(error == '007'){
          return 'At this time our system has problems!!'
        }
        if(error == '008'){
          return 'Inactive customer.'
        }
        if(error == '009'){
          return 'IAt this time our system has problems!!'
        }
        if(error == '010'){
          return 'IThe IP address is not registered. Ask the administrator for an access code'
        }
        if(error == '011'){
          return 'Invalid code, please try again!!!'
        }
        if(error == '012'){
          return 'Please try later, our server is busy!!!'
        }
        if(error == '013'){
          return 'You cant export more than 15.000 registers at same time!!!'
        }
        if(error == '014'){
          return 'Other Agent Have this ID.!!'
        }
        if(error == '015'){
          return 'You must delete other campaigns, you exceed the limit !!!!'
        }
      },
      validatePasswordAgent(password){
        let regularExpression = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{9,}/)
        if(!regularExpression.test(password)){
          return false
        }else{
          return true
        }

      },
      ord(string){//ord php
        const str = string + ''
        const code = str.charCodeAt(0)
        if (code >= 0xD800 && code <= 0xDBFF) {
          const hi = code
          if (str.length === 1) {
            return code
          }
          const low = str.charCodeAt(1)
          return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000
        }
        if (code >= 0xDC00 && code <= 0xDFFF) {
          return code
        }
        return code
      },
      chr(codePt){ //chr php
        if (codePt > 0xFFFF) {
          codePt -= 0x10000
          return String.fromCharCode(0xD800 + (codePt >> 10), 0xDC00 + (codePt & 0x3FF))
        }
        return String.fromCharCode(codePt)
      },
      encryptSIP(str_cript){
        let encriptemp = '';
        let k = 0
        for (let max = str_cript.length; k < max; k++) {
          if ((this.ord(str_cript[k])) <= 63) {
            encriptemp = encriptemp + this.chr(this.ord(str_cript[k]) + 63);
          } else {
            encriptemp = encriptemp + this.chr(this.ord(str_cript[k]) - 63);
          }
        }
        return btoa(encriptemp);
      },
      decryptSIP(str_cript){
        let encriptemp = '';
        str_cript = atob(str_cript);
        let k = 0
        for (let max = str_cript.length; k < max; k++) {
          if((this.ord(str_cript[k])) <= 63) {
            encriptemp = encriptemp + this.chr(this.ord(str_cript[k]) + 63);
          }else{
            encriptemp = encriptemp + this.chr(this.ord(str_cript[k]) - 63);
          }
        }
        return encriptemp;
      },
      fmtMSS(s){
        return(s-(s%=60))/60+(9<s?':':':0')+s
      },
      encodeUtf8(s) {
        return unescape(encodeURIComponent(s));
      },
      decodeUtf8(s){
        return decodeURIComponent(escape(s));
      },
      encodeBase64(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }));
      },
      decodeBase64(str) {
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      },
    }
  }