<template>
    <div class="text-center">
      <v-overlay opacity="1" :value="overlaySession">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <p v-if="!$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xlOnly ? 'tittle-xl' : 'tittle'">{{$t('welcome_to')}} <span class="space">wolkvox storage</span></p>
      <p v-if="$vuetify.breakpoint.xs" class="tittle-xs">{{$t('welcome_to')}}<span class="space">wolkvox manager</span></p>
      <p v-show="!changePassword" class="text-login">{{$t('login')}}</p>
      <v-container :style="(!$vuetify.breakpoint.xs) ? 'max-width: 65%; position: relative;' : 'position: relative;'">
          <v-text-field v-show="!changePassword" :label="$t('operation')" v-model="operation" outlined></v-text-field>
          <!-- <div v-if="!changePassword" class="back d-flex flex-row-reverse mt-3 mb-3"><a href="https://manager.wolkvox.com">{{$store.getters.word.changeOperation}}</a></div> -->
          <v-text-field  v-show="!changePassword" :label="$t('user')" v-model="username" outlined type="text"></v-text-field>
          <v-text-field  v-show="!changePassword" :label="$t('password')" v-model="password" outlined type="password" @keyup.enter="login()"></v-text-field>
          <v-btn v-if="!$vuetify.breakpoint.xs" v-show="!changePassword" color="#1C1133" class="white--text" elevation="2" block @click="login()" :disabled="loadingLogin">{{$t('continue')}}</v-btn>
          <v-btn v-else v-show="!changePassword" color="#1C1133" class="btn-operation-mobile" elevation="2" block @click="login()" :disabled="loadingLogin">{{$t('continue')}}</v-btn>
					<ChangePassword @succesfully="returnChangePassword" @returnLogin="returnChangePassword" v-if="changePassword" :operation="operation.toLowerCase()" :username="username.toLowerCase()"/>
          <v-expand-transition appear>
            <v-alert class="validate" dense dismissible type="error" v-if="validate" transition="scale-transition">{{$store.getters.word.invalidUser}}</v-alert>
            <v-alert class="validate" dense dismissible type="error" v-if="validateErrorsLogin" transition="scale-transition">{{messageErrors}}</v-alert>
            <v-alert class="validateOperation" dense dismissible type="error" v-if="validateOperation" transition="scale-transition">{{$t('enter_operation')}}</v-alert>
          </v-expand-transition>
      </v-container>
      <v-card>
          <v-snackbar color="#C00AC0" v-model="snackbarError" timeout="4000">
          {{snackbarErrorMessage}}
          <template v-slot:action="{ attrs }">
            <v-btn color="#1C1133" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
  </template>
  <script>
	import sharedMethods from '../../mixins/sharedMethods';
	import ChangePassword from '../Login/ChangePassword.vue';
  export default {
    name: 'LoginComponent',
    mixins: [sharedMethods],
    components: {
      ChangePassword
    },
    data(){
      return {
        username: "",
        password: "",
        passEncrypt: '',
        operation: "",
        operations: [],
        gmt: null,
        info: {},
        kTK: "",
        continuar: false,
        validateOperation: false,
        lang: {},
        validate: false,
        validateErrorsLogin: false,
        messageErrors: '',
        language: '',
        GMT: '',
        overlaySession: false,
        version: '20240131',
        snackbarError: false,
        snackbarErrorMessage: '',
        changePassword: false,
        loadingLogin: false,
        workstation: '',
        routes: [],
        countErrorPassword: 0,
        timeoutLogin: null,
      }
    },
    created() {

      //Se quema operacion en ambiente de desarrollo
      if(window.location.host == "localhost:8080" ||  window.location.host == "localhost:8081" ||  window.location.host == "localhost:8082") {
        window.history.pushState({}, document.title, "/#/");
        this.operation = "microdllo";
      }
      if(localStorage.getItem("operation")) {
        this.operation = localStorage.getItem("operation");
      }

      this.$store.dispatch("doLogout");
      this.getGMT();
      console.log("%cVersion: "+this.version,"color:#2763AE;font-family:system-ui;font-size:25px;-webkit-text-stroke: 1px black;font-weight:bold");
    },
    mounted(){

    },
    methods: {
      async login() {
        if(this.loadingLogin){
            return;
        }
        this.loadingLogin = true;
        if(this.operation != ""){
            await this.getLogin();
        }else{
            this.validateOperation = true;
            setTimeout(() => {
            this.validateOperation = false;
            }, 3500);
            this.loadingLogin = false;
        }

      },
      async getLogin(){

        // let passwordValidate = this.functionValidatePassword()
        // if(passwordValidate) return

        let formData = new FormData()
        formData.append("username", this.username.toLowerCase());
        formData.append("password", this.password);
        formData.append("operation", this.operation.toLowerCase());
        let headers = {headers:{ "Content-Type": "multipart/form-data" }}

        this.loadingPhones = true;
        let url = 'https://storage.wolkvox.com/server/login.php'
        await this.axios.post(url, formData, headers).then(async (result) => {
            this.info = {}
            this.info.code = result.data.code
            this.info.error = result.data.error
            this.info.msg = result.data.msg
						if(result.data.bug) this.info.bug = result.data.bug

						if(result.data.tokenAccess){
              this.info.tokenAccess = result.data.tokenAccess.trim()
              this.info.username = this.username.toLowerCase()
              this.info.password = this.password
              this.info.operation = this.operation.toLowerCase()
              sessionStorage.setItem("tokenAccess", this.info.tokenAccess);
              sessionStorage.setItem("username", this.username.toLowerCase());
              sessionStorage.setItem("operation", this.info.operation.toLowerCase());
              this.$store.commit("globalInfoMutation", this.info);
            }

            this.validateLogin()
            this.validateProfile()
        })
      },
      async onSubmit(){
        // sessionStorage.setItem("sessionManager", this.encodeBase64(this.username + ')_&_(' + this.password.toUpperCase()));
        try {
          await this.$store.dispatch("doLogin", this.username.toLowerCase());
          this.routes.every(element => {
            if(element.permission){
              this.$router.push({ path: element.route });
              return false
            }
            return true
          })
        } catch (error) {
          console.error(error);
        }
      },
      validateLogin(){
        if(this.info.code == "200" && !this.info.bug){
					this.onSubmit();
					this.countErrorPassword = 0;
        }else if(this.info.bug && this.info.bug == "BUG002"){
					this.countErrorPassword = 0;
          this.changePassword = true;
        }else{
					if(this.info.bug) this.validateError(this.info.bug)
        }
        this.overlaySession = false
        this.loadingLogin = false
			},

      // encriptacion hash SHA256
      getGMT(){
        let offset = new Date().getTimezoneOffset() / -60
        if(offset == '-0'){
            offset = '0'
        }
        this.GMT = offset;
      },validateError(error){
        this.overlaySession = false
        if(error == 'BUG000'){
          console.log(error)
          this.snackbarErrorMessage = this.$t('user_not_found')
          this.snackbarError = true
        }
        if(error == 'BUG001'){
          console.log(error)
          this.snackbarErrorMessage = this.$t('password_incorrect')
          this.snackbarError = true
        }
        if(error == 'BUG002'){
          console.log(error)
          this.snackbarErrorMessage = this.$t('password_expired')
          this.snackbarError = true
        }
        if(error == 'BUG003'){
          console.log(error)
          this.snackbarErrorMessage = this.$t('password_used_recently')
          this.snackbarError = true
        }
        if(error == 'BUG004'){
          console.log(error)
          this.snackbarErrorMessage = this.$t('license_active')
          this.snackbarError = true
        }

      },
      returnChangePassword(){
        this.changePassword = false;
        this.snackbarErrorMessage = this.$t('change_password_successfully')
        this.snackbarError = true
      },
      validateGMT(text){
        switch (text) {
          case '-11':
            return {text: '(GMT -11:00) Midway Island, Samoa', value: '-11'}
          case '-10':
            return {text: '(GMT -10:00) Hawaii', value: '-10'}
          case '-9':
            return {text: '(GMT -9:00) Alaska', value: '-9'}
          case '-8':
            return {text: '(GMT -8:00) Pacific Time (US & Canada)', value: '-8'}
          case '-7':
            return {text: '(GMT -7:00) Mountain Time (US & Canada)', value: '-7'}
          case '-6':
            return {text: '(GMT -6:00) Central Time (US & Canada), Mexico City', value: '-6'}
          case '-5':
            return {text: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima', value: '-5'}
          case '-4':
            return {text: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz', value: '-4'}
          case '-3.5':
            return {text: '(GMT -3:30) Newfoundland', value: '-3.5'}
          case '-3':
            return {text: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', value: '-3'}
          case '-2':
            return {text: '(GMT -2:00) Mid-Atlantic', value: '-2'}
          case '-1':
            return {text: '(GMT -1:00 hour) Azores, Cape Verde Islands', value: '-1'}
          case '0':
            return {text: '(GMT) Western Europe Time, London, Lisbon, Casablanca', value: '0'}
          case '1':
            return {text: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris', value: '1'}
          case '2':
            return {text: '(GMT +2:00) Kaliningrad, South Africa', value: '2'}
          case '3':
            return {text: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', value: '3'}
          case '3.5':
            return {text: '(GMT +3:30) Tehran', value: '3.5'}
          case '4':
            return {text: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: '4'}
          case '4.5':
            return {text: '(GMT +4:30) Kabul', value: '4.5'}
          case '5':
            return {text: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', value: '5'}
          case '5.5':
            return {text: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', value: '5.5'}
          case '6':
            return {text: '(GMT +6:00) Almaty, Dhaka, Colombo', value: '6'}
          case '7':
            return {text: '(GMT +7:00) Bangkok, Hanoi, Jakarta', value: '7'}
          case '8':
            return {text: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', value: '8'}
          case '9':
            return {text: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: '9'}
          case '9.5':
            return {text: '(GMT +9:30) Adelaide, Darwin', value: '9.5'}
          case '10':
            return {text: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', value: '10'}
          case '11':
            return {text: 'GMT +11:00) Magadan, Solomon Islands, New Caledonia', value: '11'}
          case '12':
            return {text: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', value: '12'}
          default:
            return {text: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima', value: '-5'}
        }
      },
      functionValidatePassword(){
        let validateCountPassword = window.localStorage.getItem(this.encodeBase64('validateLogin_manager'))
        if(validateCountPassword){
          this.countErrorPassword = 6;
        }
        if(this.countErrorPassword >= 5){
          console.warn(this.$t('password_incorrect_5'))
          this.snackbarErrorMessage = this.$t('password_incorrect_5')
          this.snackbarError = true;
          this.loadingLogin = true;
          if(this.timeoutLogin){
            return true
          }
          this.timeoutLogin = setTimeout(() => {
            this.countErrorPassword = 0;
            this.loadingLogin = false;
            this.snackbarErrorMessage = this.$store.getters.word.loginAgain
            this.snackbarError = true;
            window.localStorage.removeItem(this.encodeBase64('validateLogin_manager'));
            this.timeoutLogin = null
          }, 180000);
          window.localStorage.setItem(this.encodeBase64('validateLogin_manager'), true);
          return true
        }
        return false
      },
			validateProfile(){

			this.info.profileDashboard = true
			this.info.profileFiles = true


      this.routes = [
        {
          route : '/dashboard',
          permission: this.info.profileDashboard
        },
        {
          route : '/files',
          permission: this.info.profileFiles
        }
      ]

      this.$store.commit("permissionMutation", this.routes);
    },
    }
  }
  </script>

  <style scoped>
  .tittle{
    text-align: center;
    font-size: 48px;
    color: black;
    font-weight: 300;
    line-height: 60px;
  }
  .tittle span{
    font-weight: 500;
    display: block;
  }
  .tittle-xl{
    text-align: center;
    font-size: 80px;
    color: black;
    font-weight: 300;
    line-height: 90px;
  }
  .tittle-xl span{
    font-weight: 500;
    display: block;
  }
  .tittle-xs{
    text-align: center;
    font-size: 37px;
    color: black;
    font-weight: 300;
    line-height: 60px;
  }
  .tittle-xs span{
    font-weight: 500;
    display: block;
  }
  .logo{
    max-width: 120px;
    position: absolute;
    z-index: 1000;
  }
  .btn-operation-mobile{
    color: #FFFFFF !important;
    font-weight: 500;
  }
  .back{
    font-weight: 600;
    font-size: 13px;
    /* cursor: pointer; */
  }
  .validate{
    margin: auto;
    width: 100%;
    margin-top: 30px;
  }
  .validateOperation{
    margin: auto;
    width: 100%;
    margin-top: 30px;
  }
  .space{
   margin-top: -15px;
  }
  .scroll-off{
     position: fixed;
     top:0; right:0; bottom:0; left:0;
     height: 100%;
     max-height: 100%;
     overflow: hidden;
  }
  .text-login{
    font-weight: 600;
    font-size: 15px;
  }
  </style>
