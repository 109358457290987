<template>
    <v-menu :close-on-content-click="false" :offset-x='isOffsetX' :offset-y='isOffsetY' :open-on-hover='isOpenOnHover' :transition='transition' :value="openMenu" :absolute="(isSubMenu)? false : true" :position-x="positionX" :position-y="positionY">
      <template v-slot:activator="{ on }">
        <v-list-item v-if='isSubMenu' v-on="on" dense class='d-flex justify-space-between' style="font-size: 13px; font-weight: 500;">
          {{ name }}<div class="flex-grow-1"></div><v-icon small>chevron_right</v-icon>
        </v-list-item>
      </template>
      <v-list class="pt-0 pb-0">
        <template v-for="(item, index) in menuItems">
          <v-divider v-if='item.isDivider' :key='index' />
          <!-- eslint-disable-next-line -->
          <dan-menu v-else-if='item.menu' :key='index' :name='item.name' :menu-items='item.menu' @dan-menu-click='emitClickEvent' :is-open-on-hover=false :is-offset-x=true :is-offset-y=false :is-sub-menu=true />
          <!-- eslint-disable-next-line -->
          <v-list-item dense v-else :key='index' @click='emitClickEvent(item)'>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </template>
  <script>
  export default {
    name: 'dan-menu',
    props: {
      name: String,
      icon: String,
      menuItems: Array,
      positionY: Number,
      positionX: Number,
      openMenu: { type: Boolean, default: false },
      color: { type: String, default: "secondary" },
      isOffsetX: { type: Boolean, default: false},
      isOffsetY: { type: Boolean, default: true},
      isOpenOnHover: { type: Boolean, default: false },
      isSubMenu: { type: Boolean, default: false },
      transition: { type: String, default: "scale-transition" }
    },
    data: () => ({
  
    }),
    methods: {
      emitClickEvent(item) {
        this.$emit("dan-menu-click", item);
      }
    }
  }
  </script>
  