<template>
    <div class="d-flex navegation">
      <a class="select-path mx-1" @click="changePath('')">{{$store.getters.globalInfo.operation}} /</a>
      <!-- <a class="select-path mx-1" @click="changePath('')">~  /</a> -->
      <a v-for="(rute, i) in rutes" :key="i" class="select-path mx-1" @click="changePath(rute.value)">{{ rute.item }} /</a>
      <p class="selected-path mx-1 mb-0">{{ ruteSelected }}</p>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
export default {
  name: 'NavegationComponent',
  mixins: [sharedMethods],
  props:{
    path: {type: String, default: ''},
  },
  data(){
    return {
      rutes: [],
      ruteSelected: '',
    }
  },
  watch: {
    path(val){
      this.createRutes()
    }
  },
  computed: {
    
  },
  created() {
    this.createRutes()                                              
  },mounted(){

  },methods: {
    createRutes(){
      this.rutes = this.path.split('/');
      if(this.rutes.length > 0){
        this.ruteSelected = this.rutes[this.rutes.length - 2]
        this.rutes.splice(this.rutes.length - 2, 2);
      }
      this.rutes = this.rutes.map((item, index) => ({
        item,
        value: this.rutes.slice(0, index + 1).join('/') + '/',
      }));

    },
    changePath(path){
      this.$emit('changeNavegation', path);
    }
  }
}
</script>
<style scoped>
.navegation a, p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.text-files{
	color: #061A40;
  font-size: 23px;
	font-weight: 700;
}

.select-path{
  color: #061A40;
  font-size: 15px;
	font-weight: 700;
}
.selected-path{
  color: #640964;
  font-size: 15px;
	font-weight: 700;
}
</style>
