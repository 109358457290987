<template>
    <div>
        <v-speed-dial bottom left v-model="fab">
            <template v-slot:activator>
                <v-btn fab color="indigo">
                    <v-icon color="#FFFFFF" v-if="fab">close</v-icon>
                    <v-icon color="#FFFFFF" v-else>add</v-icon>
                </v-btn>
            </template>
            <v-tooltip left>
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn fab dark small color="purple darken-3" @click="openFileInput()" v-bind="attrs" v-on="on">
                        <v-icon>description</v-icon>
                    </v-btn>
                </template>
              <span>{{$t('file_upload')}}</span>
            </v-tooltip>
            <v-tooltip left>
                 <template v-slot:activator="{ on, attrs }">
                    <v-btn fab dark small color="blue-grey " v-bind="attrs" v-on="on" @click="dialogCreateFolder = true;">
                        <v-icon>folder</v-icon>
                    </v-btn>
                </template>
              <span>{{ $t('folder_create') }}</span>
            </v-tooltip>
        </v-speed-dial>

        <v-dialog v-model="dialogCreateFolder" max-width="400" persistent>
          <v-card class="scroll">
            <v-card-title><p class="text-dialog-confirm mb-0">{{ $t('folder_new') }}</p></v-card-title>
            <v-card-text class="mb-1">
              <div class="ma-2 mb-4">
                  <v-text-field outlined dense :label="$t('folder_no_title')" v-model="nameFolder"></v-text-field>
              </div>
              <v-row justify="end">
                <div>
                  <v-btn color="#C00AC0" @click="createFolder()" class="mr-3 white--text">{{$t('accept')}}</v-btn>
                  <v-btn outlined color="#061A40" @click="dialogCreateFolder = false;">{{$t('cancel')}}</v-btn>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <input type="file" ref="fileIn" style="display: none" accept="image/jpg,image/jpeg,audio/mp3,audio/wav,audio/gsm,.pdf,.mp4,.svg" @change="onFileChangeIn">
        <v-snackbar color="#C00AC0" v-model="snackbarError" timeout="4000">
            {{snackbarErrorMessage}}
            <template v-slot:action="{ attrs }">
            <v-btn color="#1C1133" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
export default {
  name: 'FloatingButtonComponent',
  mixins: [sharedMethods],
  props:{
    path: {type: String, default: ''},
  },
  data(){
    return {
        fab: false,
        snackbarError: false,
        snackbarErrorMessage: '',
        dialogCreateFolder: false,
        nameFolder: '',
    }
  },
  watch: {
    path(val){
    }
  },
  computed: {

  },
  created() {

  },mounted(){

  },methods: {
    openFileInput() {
      this.$refs.fileIn.value = null;
      this.$refs.fileIn.click();
    },
    onFileChangeIn(e){
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length){
        return;
      }
      this.addFile(files[0])
    },
    async addFile(file){
      if(!file){
        this.snackbarErrorMessage = this.$t('file_upload_please')
        this.snackbarError = true
        return
      }
      let formData = new FormData()
      formData.append("username", this.$store.getters.globalInfo.username);
      formData.append("operation", this.$store.getters.globalInfo.operation);
      formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
      formData.append("action", 'uploadFile');
      formData.append("file", file);
      formData.append("path", this.path);
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      this.loadingFiles = true;
      let url = 'https://storage.wolkvox.com/server/controller.php'
      await this.axios.post(url, formData, headers).then(async (result) => {
          this.loadingFiles = false;
          if(result.data.code == '200'){
            this.snackbarErrorMessage = this.$t('file_upload_successfully')
            this.snackbarError = true
          }else{
            this.snackbarErrorMessage = this.$t('been_problem_upload_file')
            this.snackbarError = true
          }
          this.$emit('refreshFiles');
      })
      .catch((error) => {
        console.error(error)
        this.loadingFiles = false;
        this.snackbarErrorMessage = this.$t('been_problem_upload_file')
        this.snackbarError = true
      })
    },
    async createFolder(){
        this.nameFolder = this.nameFolder.trim();
        this.nameFolder = this.nameFolder.replace(/[\s]/g, '_');
        if (this.nameFolder == '') {
            this.snackbarErrorMessage = this.$t('file_name_not_empty')
            this.snackbarError = true
            return;
        }

        this.dialogCreateFolder = false;

        let formData = new FormData()
        formData.append("username", this.$store.getters.globalInfo.username);
        formData.append("operation", this.$store.getters.globalInfo.operation);
        formData.append("bucket_name", 'wvox-'+this.$store.getters.globalInfo.operation);
        formData.append("action", 'createFolder');
        formData.append("folder_name", this.nameFolder);
        formData.append("path", this.path);
        let headers = {};
        if (process.env.NODE_ENV === 'production') {
          headers = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
            },
            withCredentials: true
          }
        } else {
          //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
        }

        let url = 'https://storage.wolkvox.com/server/controller.php'
        await this.axios.post(url, formData, headers).then(async (result) => {
            if(result.data.code == '200'){
                this.snackbarErrorMessage = this.$t('folder_create_successfully')
                this.snackbarError = true
            }else{
                this.snackbarErrorMessage = this.$t('been_problem_create_file')
                this.snackbarError = true
            }
            this.$emit('refreshFiles');
        })
        .catch((error) => {
            console.error(error)
            this.loadingFiles = false;
            this.snackbarErrorMessage = this.$t('been_problem_create_file')
            this.snackbarError = true
        })
        this.nameFolder = '';
    }
  }
}
</script>
<style scoped>

.v-speed-dial {
    position: absolute;
}

.v-btn--floating {
    position: relative;
}
</style>
