import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    doLogin({ commit }, username) {
      commit("doLogin", username);
    },
    doLogout({ commit }) {
      commit("doLogout");
    },
    doReturn({ commit }) {
      commit("doReturn");
    }
  },
  modules: {},
  state: {
    words: {},
    username: null,
    auth: null,
    globalInfo: {},
    permission: {},
  },
  mutations: {
    doLogin(state, username) {
      state.auth = true;
      state.username = username;
    },
    doLogout(state) {
      state.auth = false;
      state.username = null;
    },
    doReturn(state) {
      state.globalInfo.codeAccessActive = false;
    },
    changeWords(state, words) {
      let comp = this
      comp.state.words = words
    },
    globalInfoMutation(state, globalInfoParam) {
      this.state.globalInfo = {}
      this.state.globalInfo = globalInfoParam
    },
    permissionMutation(state, permissionParam) {
      this.state.permission = {}
      this.state.permission = permissionParam
    },
  },
  getters: {
    word: state => state.words,
    globalInfo: state => state.globalInfo,
    permission: state => state.permission,
  },
});


