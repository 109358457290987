<template>
    <div class="text-center">
        <h1>404</h1>
        <h2>Not Found!</h2>
        <p>
        <a class="link-return" href="/">Ir al inicio</a>
        </p>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    created(){
        this.$store.dispatch("doLogout");
    }
}
</script>

<style scoped>
h1{
    margin-top: 2em;
    color: var(--border);
    font-size: 5em;
}
h2{
    color: var(--border);
    font-size: 4em;
}
.link-return{
    font-weight: 600;
    font-size: 17px;
}
</style>