<template>
    <div>
        <p class="title-code">{{$t('restore_password')}}</p>
        <p class="text-code">{{$t('password_expired_change')}}</p>
        <div class="back mt-3 mb-5"><a @click="returnLogin()">{{$t('back')}}</a></div>
        <v-text-field hide-details class="mb-3" :label="$t('password_old')" v-model="oldPassword" outlined type="password"></v-text-field>
        <v-text-field hide-details class="mb-3" :label="$t('password_new')" v-model="newPassword" outlined type="password" @keyup.enter="login()"></v-text-field>
        <v-text-field :label="$t('password_confirm')" v-model="newPassword2" outlined type="password" @keyup.enter="changePassword()"></v-text-field>
        <v-btn v-if="!$vuetify.breakpoint.xs" elevation="2" block @click="changePassword()" color="#1C1133" class="white--text">{{$t('password_change')}}</v-btn>
        <v-snackbar color="#061A40" v-model="snackbarError" timeout="4000">
          {{snackbarErrorMessage}}
          <template v-slot:action="{ attrs }">
            <v-btn color="#F2950D" text v-bind="attrs" @click="snackbarError = false">{{$t('accept')}}</v-btn>
          </template>
      </v-snackbar>
    </div>
</template>
<script>

import sharedMethods from '../../mixins/sharedMethods.js'
export default {
  name: 'ChangePasswordComponent',
  mixins: [sharedMethods],
  props: {
    username: String,
    operation: String,
  },
  data(){
    return {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      snackbarError: false,
      snackbarErrorMessage: '',
    }
  },
  computed: {

  },
  created() {

  },mounted(){

  },methods: {
    changePassword(){


      if(this.newPassword != this.newPassword2){
        this.snackbarErrorMessage = this.$t('password_confirm_please')
        this.snackbarError = true
        return;
      }

      if(!this.validatePassword(this.newPassword)){
        return;
      }

      if(this.username == '' || this.operation == ''){
          this.snackbarErrorMessage = this.$t('back_please_enter')
          this.snackbarError = true
          return;
      }

      let formData = new FormData()
      formData.append("username", this.username);
      formData.append("operation", this.operation);
      formData.append("oldPass", this.oldPassword);
      formData.append("newPass", this.newPassword);
      formData.append("action", 'changePass');
      let headers = {};
      if (process.env.NODE_ENV === 'production') {
        headers = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`
          },
          withCredentials: true
        }
      } else {
        //utilizar para desarrollo(localhost)
        headers = {
          headers:{
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${this.$store.getters.globalInfo.tokenAccess}`,
            "Cache-Control": "no-cache",
          }
        }
      }

      let url = 'https://storage.wolkvox.com/server/controller.php'
      this.axios.post(url, formData, headers).then(async (result) => {
          if(result.data.code == '200'){
            this.snackbarErrorMessage = this.$t('change_password_succesfully')
            this.snackbarError = true
            this.returnSuccesfully()
          }

          if(result?.data?.bug){
            this.validateBug(result?.data?.bug)
          }
      })
      .catch( (error) => {
          console.error(error)
      })
    },
    returnLogin(){
      this.$emit('returnLogin');
    },
    returnSuccesfully(){
      this.$emit('succesfully');
    },
    validatePassword(password){
      try {
        let hasUpperCase = /[A-Z]/.test(password);
        let hasLowerCase = /[a-z]/.test(password);
        let hasNumber = /\d/.test(password);
        let hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
        let isValidLength = password.length >= 10 && password.length <= 20;

        if (!hasUpperCase) {
          this.snackbarErrorMessage = this.$t('has_uppercase')
          this.snackbarError = true
          return false;
        }

        if (!hasLowerCase) {
          this.snackbarErrorMessage = this.$t('has_lowercase')
          this.snackbarError = true
          return false;
        }

        if (!hasNumber) {
          this.snackbarErrorMessage = this.$t('has_number')
          this.snackbarError = true
          return false;
        }

        if (!hasSpecialChar) {
          this.snackbarErrorMessage = this.$t('has_special_char')
          this.snackbarError = true
          return false;
        }

        if (!isValidLength) {
          this.snackbarErrorMessage = this.$t('is_valid_length')
          this.snackbarError = true
          return false;
        }

        return true;
      }catch(error){
        console.error(error);
        this.snackbarErrorMessage = this.$t('password_incorrect')
        this.snackbarError = true
        false
      }

    },
    validateBug(bug){
      if(bug == 'BUG006'){
        this.snackbarErrorMessage = this.$t('password_incorrect')
        this.snackbarError = true
      }
    }
  }
}
</script>
<style scoped>
.title-code{
  font-weight: 600;
  font-size: 15px;
}
.text-code{
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  font-size: 14px;
}
.v-otp-input >>> input {
  background-color: #eeeeee!important;
}
.v-otp-input {
  flex-wrap: nowrap!important;
}
.back{
  margin-top: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  /* cursor: pointer; */
}
</style>
